import { MutationFunction } from 'react-query'

import { User } from './types'
import { v1 } from '@services/api'

export const updateUser: MutationFunction<
  void,
  Partial<Omit<User, 'picture' | 'name' | 'fullAddress'>>
> = async variables => {
  await v1.put('/users', variables)
}
