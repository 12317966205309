import { useContext, useEffect } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'

import { LoginParams, UserHookBag } from './types'
import { UserContext } from './context'
import { createClient } from '@services/api'
import { updateUser } from './fetchers'
import { SignupDetails } from '@models/user'
import axios from 'axios'
import router from 'next/router'

interface UserHookOptions {
  redirectOnUnauthorized?: boolean
}

export function useUser(options?: UserHookOptions): UserHookBag {
  const queryClient = useQueryClient()
  const tokenHelpers = useContext(UserContext)

  const user = useQuery(
    'user',
    async () => {
      const baseURL = process.env.NEXT_PUBLIC_API_HOSTNAME
      const client = axios.create({
        baseURL: `${baseURL}/api/1`,
        withCredentials: true
      })

      client.interceptors.request.use(
        async function (config) {
          if (process.browser) {
            const token = localStorage.getItem('majordomo-token')

            if (token) {
              config.headers.Authorization = token
            }
          }

          return config
        },
        async error => await Promise.reject(error)
      )

      client.interceptors.response.use(
        async res => res,
        async error => {
          if (error.response.status === 401) {
            tokenHelpers.removeToken()
            if (options?.redirectOnUnauthorized) {
              void router.push('/login')
            }
          }

          return await Promise.reject(error)
        }
      )

      const { data } = await client.get('/users')

      return {
        name: `${data.user_first_name} ${data.user_last_name}`,
        partnerId: data.partnerId,
        firstName: data.user_first_name,
        lastName: data.user_last_name,
        email: data.user_email,
        phone: data.phone_number,
        age: data.user_age,
        type: data.user_type,
        picture: data.user_profile_pic,
        fullAddress: `${data.user_log_address_full}, ${data.user_log_address_city}, ${data.user_log_address_state} ${data.user_log_address_ZIP}`,
        address: {
          street: data.user_log_address_full,
          city: data.user_log_address_city,
          state: data.user_log_address_state,
          zipCode: data.user_log_address_ZIP
        }
      }
    },
    {
      enabled: tokenHelpers.hasToken
    }
  )

  const updateMutation = useMutation(updateUser, {
    onSuccess: async () => await queryClient.invalidateQueries('user')
  })

  useEffect(() => {
    void queryClient.invalidateQueries('user')
  }, [tokenHelpers.hasToken])

  const loginMutation = useMutation(async (variables: LoginParams) => {
    const { data } = await createClient().post('/users/login', variables)

    tokenHelpers.setToken(data.token)
  })

  const logoutMutation = useMutation(
    async (): Promise<void> => {
      await createClient().get('/users/logout')

      tokenHelpers.removeToken()
    }
  )

  const registerMutation = useMutation(
    async (variables: Omit<SignupDetails, 'confirmPassword' | 'acceptTOC'>) => {
      const { data } = await createClient().post('/users/register', variables)

      tokenHelpers.setToken(data.token)
    },
    {
      onSuccess: async () => await queryClient.invalidateQueries('user')
    }
  )

  return {
    user,
    isLoggedIn: tokenHelpers.hasToken,
    updateInfo: updateMutation,
    login: loginMutation,
    logout: logoutMutation,
    register: registerMutation
  }
}
